<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <img
                      src="@/assets/images/logo/logo bioherbs-01.png"
                      width="200px"
                      height="auto"
                    >
                    <h3 class="text-primary invoice-logo" />
                  </div>
                  <b-card-text class="mb-0">
                    Route Lido Dar Chabane , 8000 Nabeul
                  </b-card-text>
                  <b-card-text class="mb-0">
                    contact@bioherbs.tn
                  </b-card-text>
                  <b-card-text class="mb-0">
                    www.bioherbs.tn
                  </b-card-text>
                  <b-card-text class="mb-0">
                    +216 24 289 289
                  </b-card-text>
                  <b-card-text class="mb-0">
                    MF : 000MB1704494/H
                  </b-card-text>

                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-datedate mt-md-0 mt-2">
                  <!-- <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Invoice
                    </h4>
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id"
                        v-model="generate"
                        disabled
                      />
                    </b-input-group>
                  </div> -->
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title" />
                    <flat-pickr
                      v-model="invoiceData.date"
                      class="form-control invoice-edit-input"
                    />
                  </div>

                </div>
              </div>
            </b-card-body>
            <!-- Spacer -->
            <hr class="invoice-spacing">
            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >
              <b-row>

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="6"

                  class="mb-lg-1"
                >
                  <h6 class="mb-1">
                    Vente pour:
                  </h6>
                  <!-- Select Client -->
                  <v-select
                    v-model="invoiceData.client"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="clients"
                    label="company_name"
                    input-id="invoice-data-client"
                    :clearable="false"
                  >
                    <template #list-header>
                      <li
                        v-b-toggle.sidebar-invoice-add-new-customer
                        class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-25">Ajouter un noveau client</span>
                      </li>
                    </template>
                  </v-select>

                  <!-- Selected Client -->
                  <div
                    v-if="invoiceData.client"
                    class="mt-1"
                  >
                    <h6
                      class="mb-25"
                    >
                      Client : {{ invoiceData.client.company_name }}
                    </h6>
                    <b-card-text class="mb-25">
                      Adresse : {{ invoiceData.client.address }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      Email : {{ invoiceData.client.email }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      Tel : {{ invoiceData.client.phone }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      MF/CIN : {{ invoiceData.client.tax_identification_number }}
                    </b-card-text>
                  </div>
                </b-col>
                <feather-icon
                  size="16"
                  icon="XOctagonIcon"
                  class="cursor-pointer mt-4"
                  @click="invoiceData.client=null"
                />

              </b-row>
              <b-form-group
                label="Référence de produit"
                label-for="vi-first-name"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MaximizeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    ref="select"
                    v-model="reference"
                    placeholder="Référence de produit"
                    @input="checkColis"
                  />
                </b-input-group>
              </b-form-group>
            </b-card-body>

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{height: trHeight}"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">

                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Référence
                        </b-col>
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Labelle
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Prix de vente
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Quantité
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Prix total
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>
                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Item</label>
                          <v-select
                            ref="select"
                            v-model="item.produit"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="itemsOptions"
                            label="reference"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"

                            @input="setSelected(item.produit,index)"
                          />

                        </b-col>
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Labelle</label>
                          <b-form-input
                            v-if="item.produit.reference != '' && item.produit.name == ''"
                            v-model="item.produit.name"
                            class="mb-2"
                          />
                          <v-select
                            v-else
                            v-model="item.produit"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="itemsOptions"
                            label="name"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            @input="setSelected(item.produit,index)"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Prix de gros</label>
                          <b-form-input
                            v-model="item.produit.wholesale_price_ttc"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Qty</label>
                          <b-form-input
                            v-model="item.qty"
                            value="1"
                            type="number"
                            class="mb-2"
                            :on-change="alertt(item.produit.quantity,item.qty,index)"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Prix</label>
                          <p

                            class="mb-1"
                          >
                            {{ (item.produit.wholesale_price_ttc * item.qty).toFixed(3) }}
                          </p>

                        </b-col>

                      </b-row>
                      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />
                      </div>
                    </div></b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItem"
              >
                Ajouter produit
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                  <label
                    for="invoice-data-sales-person"
                    class="text-nowrap mr-50"
                  >Remise</label>
                  <b-form-input
                    id="invoice-data-sales-person"
                    v-model="remise"
                    placeholder="Edward Crowley"
                  />
                  <span class="ml-2">%</span>
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div
                      v-if="totalTTC"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Total :
                      </p>
                      <p class="invoice-total-amount">
                        {{ totalTTC }} DT

                      </p>
                    </div>

                  </div></b-col></b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <quill-editor
                ref="myQuillEditor"
                v-model="invoiceData.note"
              />
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >

        <b-card>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            @click="save"
          >
            Enregistrer
          </b-button>
        </b-card>

      </b-col>
    </b-row>
    <invoice-sidebar-add-new-customer />

  </section>

</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { quillEditor } from 'vue-quill-editor'
import InvoiceSidebarAddNewCustomer from './AddClient.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    vSelect,
    InvoiceSidebarAddNewCustomer,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    quillEditor,
  },

  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  data() {
    return {
      reference: '',
      exist: false,
    }
  },
  computed: {

    invoicenum() {
      return this.orders.length
    },
    generate() {
      return Number(this.invoicenum) + 1
    },

    totalHT() {
      if (this.remise === 0) {
        return this.invoiceData.items.reduce((accumulator, item) => accumulator + (item.produit.wholesale_price * item.qty), 0)
      }

      const somme = this.invoiceData.items.reduce((accumulator, item) => accumulator + (item.produit.wholesale_price * item.qty), 0)
      return (somme - ((somme * this.remise) / 100))
    },
    totalTTC() {
      return ((Number(this.totalHT) * (1 + (19 / 100)))).toFixed(3)
    },
    tax() {
      return (Number(this.totalHT) / (100 / 19)).toFixed(3)
    },
  },

  mounted() {
    this.$refs.select.focus()
    this.initTrHeight()
  },
  created() {
    this.invoiceData.items.shift()
    this.getOrders()
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    setSelected(value, index) {
      for (let i = 0; i < this.invoiceData.items.length; i += 1) {
        if (this.invoiceData.items[i].produit.reference === value.reference) {
          this.invoiceData.items[i].qty = Number(this.invoiceData.items[i].qty) + 1
          if (this.invoiceData.items[i].qty > 1) { this.removeItem(index) }
        }
      }
    },
    async reload() {
      this.getProducts()
      const current = new Date()
      this.invoiceData = {
        id: '',
        client: null,
        date: `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`,

        // ? Set single Item in form for adding data
        items: [],

        salesPerson: '',
        note: '',
        paymentMethod: null,
      }
    },
    async refresh() {
      this.exist = false
    },
    checkColis() {
      const result = this.itemsOptions.find(element => element.reference === this.reference)
      this.exist = false
      for (let i = 0; i < this.invoiceData.items.length; i += 1) {
        if (this.invoiceData.items[i].produit.reference === this.reference) {
          this.invoiceData.items[i].qty = Number(this.invoiceData.items[i].qty) + 1
          this.exist = true
        }
      }
      if (result) {
        if (this.exist === false) {
          this.addNewItemInItemForm(result).then(() => { this.reference = '' })
        } else {
          this.refresh().then(() => { this.reference = '' })
        }
      }
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)

      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')}`
    },
    alertt(quantity, qty, index) {
      if (quantity < qty) {
        // eslint-disable-next-line no-param-reassign
        qty = quantity

        this.withTitle(quantity, index)
      }
    },
    withTitle(quantity, index) {
      this.$swal({
        title: 'Stock Alerte',
        icon: 'warning',
        text: `Quantité demandé est superieur au stock       il vous reste que ${quantity} pièce`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // if (quantity === 0) {
          //   this.removeItem(index)
          // } else {
          //   const item = this.invoiceData.items.slice(index, index + 1)
          //   item[0].qty = quantity
          // }

          this.removeItem(index)
        }
      })
    },
    async messageError() {
      this.$swal({
        title: 'Produit non existe',
        icon: 'warning',
        text: 'Produit non existe',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
    },

    async getOrders() {
      await axios.get('/orders/').then(response => {
        this.orders = response.data
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    async getProducts() {
      await axios.get('/products/').then(response => {
        this.itemsOptions = response.data
      })
    },
    async save() {
      const date = new Date()
      const now = this.formatFn(date.toISOString())
      let customer
      if (this.invoiceData.client) {
        customer = this.invoiceData.client.id
      } else {
        customer = null
      }

      const obj = {
        reference: this.generate,
        date: this.invoiceData.date,
        status: 'vente gros',
        note: this.invoiceData.note,
        customer,
        remise: this.remise,
        products: this.invoiceData.items,
        total_ht: this.totalHT.toFixed(3),
        total_ttc: this.totalTTC,
        tax: this.tax,
        date_today: now,
      }
      await axios.post('/orders/create-all/', obj).then(() => {
        this.reload()
        this.showToast('success', 'top-center', 'Vente ajouté avec succés')
      })
        .catch(error => {
          this.showToast('danger', 'top-center', error.toString())
        })
    },

    async addNewItemInItemForm(result) {
      this.$refs.form.style.overflow = 'hidden'
      const itemFormBlankItem = {
        item: null,
        produit: result,
        qty: 1,
      }
      this.invoiceData.items.push(JSON.parse(JSON.stringify(itemFormBlankItem)))
    },
    async addNewItem(result) {
      this.$refs.form.style.overflow = 'hidden'
      const itemFormBlankItem = {
        item: null,
        produit: result,
        qty: 0,
      }
      this.invoiceData.items.push(JSON.parse(JSON.stringify(itemFormBlankItem)))
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const clients = ref([])
    axios.get('/customers/', { params: { type: 'client morale' } }).then(response => {
      clients.value = response.data
    })

    const itemFormBlankItem = {
      item: null,
      produit: {
        wholesale_price: 0,
        reference: '',
      },
      qty: 0,
    }
    const orders = ref([])
    const current = new Date()

    const invoiceData = ref({
      id: '',
      client: null,
      date: `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      salesPerson: '',
      note: '',
      paymentMethod: null,
    })

    const itemsOptions = ref([])
    axios.get('/products/').then(response => {
      itemsOptions.value = response.data
    })
    const remise = 0

    const updateItemForm = (index, val) => {
      const { cost, qty } = val
      invoiceData.value.items[index].cost = cost
      invoiceData.value.items[index].qty = qty
    }

    const paymentMethods = [
      'Bank Account',
      'PayPal',
      'UPI Transfer',
    ]

    return {
      invoiceData,
      clients,
      itemsOptions,
      updateItemForm,
      itemFormBlankItem,
      paymentMethods,
      orders,
      remise,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
